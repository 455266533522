import { useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-hot-toast";
import { RiKey2Line } from "react-icons/ri";
import { HiOutlineLockClosed } from "react-icons/hi2";
import { IoHelpCircleOutline } from "react-icons/io5";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";

import "swiper/css";

import Loader from "../../components/Loader";
import CustomToast from "../../components/CustomToast";
import API from "../../services/api";
import VisaPrepaid from "../../assets/visa-prepaid-card.png";
import { BlockModal, UnblockModal, UnblockModalBlockedUser } from "./modals";
import { TransactionsList } from "./transactions";
import { maskEmail } from "../../utils";

require("dayjs/locale/en");
require("dayjs/locale/fr");

const Wallet = () => {
  const location = useLocation();
  const searchParams = location.search;

  const [loading, setLoading] = useState(true);
  const [statementLoading, setStatementLoading] = useState(false);
  const [previousUnblockToken, setPreviousUnblockToken] = useState(null);
  const [isShowingPIN, setIsShowingPIN] = useState(false);
  const [isLoadingPIN, setIsLoadingPIN] = useState(false);
  const [isShowingBlock, setIsShowingBlock] = useState(false);
  const [isShowingUnblock, setIsShowingUnblock] = useState(false);
  const [activeDevice, setActiveDevice] = useState(null);
  const [activeWalletIndex, setActiveWalletIndex] = useState(0);
  const [wallets, setWallets] = useState([]);
  const [devices, setDevices] = useState([]);
  const swiperRef = useRef(null);
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.Auth);

  const showPIN = async () => {
    if (isLoadingPIN) return;
    if (isShowingPIN) return setIsShowingPIN(false);
    if (activeDevice.PIN) return setIsShowingPIN(true);
    setIsLoadingPIN(true);
    const res = await API.get(`/device/PIN?deviceId=${activeDevice.id}`);
    setIsLoadingPIN(false);
    if (!res.ok || !res.data?.pinNumber)
      return toast.custom(
        () => (
          <CustomToast
            toast={toast}
            title={t("generic-error.title")}
            description={t("generic-error.description")}
            status="GENERIC_ERROR"
          />
        ),
        {
          position: "top-right",
        }
      );
    setActiveDevice({ ...activeDevice, PIN: res.data?.pinNumber });
    setDevices(
      devices.map((d) =>
        d.id === activeDevice.id ? { ...d, PIN: res.data?.pinNumber } : d
      )
    );
    setIsShowingPIN(true);
  };

  const unblock = async () => {
    const res = await API.post("/device/unblock", {
      deviceNumber: activeDevice.deviceNumber,
    });
    if (!res.ok)
      return toast.custom(
        () => (
          <CustomToast
            toast={toast}
            title={t("generic-error.title")}
            description={t("generic-error.description")}
            status="GENERIC_ERROR"
          />
        ),
        {
          position: "top-right",
        }
      );
    setIsShowingUnblock(true);
  };

  const verifyUnblock = async (unblockToken) => {
    const res = await API.post("/device/unblockToken", {
      deviceNumber: activeDevice.deviceNumber,
      unblockToken,
    });
    if (!res.ok) {
      if (res.errorCode === "UNBLOCK_TOKEN_EXPIRED") {
        return toast.custom(
          () => (
            <CustomToast
              status={"FAILURE"}
              toast={toast}
              title={t("wallet.unblockToken.title-failure")}
              description={t("wallet.unblockToken.description-failure")}
            />
          ),
          {
            position: "top-right",
          }
        );
      }
      return toast.custom(
        () => (
          <CustomToast
            toast={toast}
            title={t("generic-error.title")}
            description={t("generic-error.description")}
            status="GENERIC_ERROR"
          />
        ),
        {
          position: "top-right",
        }
      );
    }
    toast.custom(
      () => (
        <CustomToast
          status={"SUCCESS"}
          toast={toast}
          title={
            activeDevice.type === "CARD"
              ? t("wallet.unblockToken.title-success-card")
              : t("wallet.unblockToken.title-success-wearable")
          }
          description={
            activeDevice.type === "CARD"
              ? t("wallet.unblockToken.description-success-card")
              : t("wallet.unblockToken.description-success-wearable")
          }
        />
      ),
      {
        position: "top-right",
      }
    );
    //remove search params from url &&  timeout 500 ms for ux
    fetchData();
    await new Promise((resolve) => setTimeout(resolve, 500));
    const url = new URL(window.location.href);
    url.search = "";
    window.history.replaceState({}, "", url);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get("/wallet");
      if (!res.ok)
        return toast.custom(
          () => (
            <CustomToast
              toast={toast}
              title={t("generic-error.title")}
              description={t("generic-error.description")}
              status="GENERIC_ERROR"
            />
          ),
          {
            position: "top-right",
          }
        );
      setDevices(res.data.devices);
      setWallets(res.data.wallets);
      setActiveWalletIndex(0);
      setActiveDevice(res.data.devices[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!searchParams || !activeDevice || !activeDevice.isBlocked) return;
    const unblockToken = new URLSearchParams(searchParams).get("unblockToken");
    if (!unblockToken) return;
    setPreviousUnblockToken(unblockToken);
    if (previousUnblockToken === unblockToken) return;
    verifyUnblock(unblockToken);
  }, [searchParams, activeDevice]);

  const handleDownload = async () => {
    setStatementLoading(true);
    const res = await API.post("/wallet/statement", {
      deviceNumber: activeDevice.deviceNumber,
    });
    setStatementLoading(false);
    if (!res.ok) {
      if (res.errorCode === "ALREADY_GENERATED") {
        const date = new Date(res.errorData.statementBlockedUntil);
        const remainingHours =
          Math.floor((date.getTime() - new Date().getTime()) / 1000 / 60 / 60) +
          1;
        return toast.custom(
          () => (
            <CustomToast
              toast={toast}
              status={"WARNING"}
              title={t("wallet.statement.title-warning")}
              description={t("wallet.statement.body-warning", {
                hours: remainingHours,
              })}
            />
          ),
          {
            position: "top-right",
          }
        );
      }
      return toast.custom(
        () => (
          <CustomToast
            toast={toast}
            title={t("generic-error.title")}
            description={t("generic-error.description")}
            status="GENERIC_ERROR"
          />
        ),
        {
          position: "top-right",
        }
      );
    }
    toast.custom(
      () => (
        <CustomToast
          toast={toast}
          title={t("wallet.download.title")}
          description={t("wallet.download.description", {
            email: maskEmail(user.email),
          })}
        />
      ),
      {
        position: "top-right",
      }
    );
  };

  const handlePrev = () => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  };

  const handleNext = () => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  };

  if (loading) return <Loader />;

  return (
    <section className="md:my-6  md:bg-white rounded-[1.875rem] md:shadow-app  max-w-[29.25rem] mx-auto flex flex-col items-center md:justify-center gap-6 md:p-6 p-4">
      <div className="space-y-3 w-full px-6">
        <div className="flex items-center justify-between gap-6">
          {wallets.length > 1 ? (
            <button
              className="w-10 h-10 grid place-items-center disabled:opacity-40"
              onClick={handlePrev}
              disabled={activeWalletIndex === 0}
            >
              <GoChevronLeft size={24} />
            </button>
          ) : (
            <div className="w-10 h-10" />
          )}

          <Swiper
            className="w-full"
            slidesPerView={1}
            spaceBetween={16}
            ref={swiperRef}
            onSlideChange={(swiper) => {
              setIsShowingPIN(false);
              setActiveDevice(
                devices.find((d) => devices[swiper.activeIndex].id === d.id)
              );
              setActiveWalletIndex(swiper.activeIndex);
            }}
          >
            {wallets.map((wallet) => (
              <SwiperSlide key={wallet._id}>
                <div className="rounded-lg p-3 text-white flex-1  w-full bg-gradient-to-r from-[#2A83BE] to-[#295D8C]">
                  <div>
                    <p className="text-xs leading-4">
                      {isShowingPIN
                        ? t("wallet.card.pin")
                        : t("wallet.card.availableBalance")}
                    </p>

                    <h1 className="text-3xl font-bold text-white my-0.5">
                      {isLoadingPIN ? (
                        <div className="w-6/12 h-11/12">
                          <Skeleton
                            sx={{ bgcolor: "rgba(255, 255, 255, 0.50);" }}
                            variant="rounded"
                            animation="wave"
                          />
                        </div>
                      ) : (
                        <div>
                          {isShowingPIN
                            ? activeDevice.PIN
                            : wallet.amount?.toFixed(2) + "€"}
                        </div>
                      )}
                    </h1>
                    <p className="text-[0.625rem] leading-4 h-4">
                      {isShowingPIN ? "" : t("wallet.card.availableUntil")}
                    </p>
                  </div>

                  <div className="flex items-end justify-between">
                    <p className="leading-4 text-xs ">
                      ID :{" "}
                      <span className="font-semibold">
                        {activeDevice.xpollensCardId}
                      </span>
                    </p>
                    <img src={VisaPrepaid} alt="" className="h-8" />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {devices.length > 1 ? (
            <button
              className="w-10 h-10 grid place-items-center disabled:opacity-40"
              onClick={handleNext}
              disabled={activeWalletIndex === devices.length - 1}
            >
              <GoChevronRight size={24} />
            </button>
          ) : (
            <div className="w-10 h-10" />
          )}
        </div>

        <div className="flex items-start justify-center gap-2">
          <button
            className={`flex flex-col items-center justify-center gap-y-1 w-[3.75rem] transition-all ${
              isShowingPIN ? "font-semibold" : "font-normal"
            } ${activeDevice.isBlocked || isLoadingPIN ? "opacity-30" : ""} `}
            onClick={showPIN}
            disabled={activeDevice.isBlocked || isLoadingPIN}
          >
            <div
              className={`w-6 h-6 rounded-full border border-app-grey grid place-items-center transition-colors ${
                isShowingPIN && "bg-app-grey text-white"
              }`}
            >
              <RiKey2Line size={14} />
            </div>
            <p className="text-xs leading-4">
              {isShowingPIN ? t("wallet.hidePIN") : t("wallet.showPIN")}
            </p>
          </button>
          <button
            className="flex flex-col items-center justify-center gap-y-1 w-[3.75rem]"
            onClick={() => {
              activeDevice.isBlocked ? unblock() : setIsShowingBlock(true);
            }}
          >
            <div className="w-6 h-6 rounded-full border border-app-grey grid place-items-center">
              <HiOutlineLockClosed size={14} />
            </div>
            <p className="text-xs leading-4">
              {activeDevice.isBlocked ? t("wallet.unblock") : t("wallet.block")}
            </p>
          </button>
          <a
            href="https://vportal.zendesk.com/hc/fr"
            target="_blank"
            rel="noreferrer"
            className="flex flex-col items-center justify-center gap-y-1 w-[3.75rem]"
          >
            <div className="w-6 h-6 rounded-full border border-app-grey grid place-items-center">
              <IoHelpCircleOutline size={14} />
            </div>
            <p className="text-xs leading-4">{t("wallet.help")}</p>
          </a>
        </div>
      </div>
      <div className="w-full bg-[#F8F8F8] p-6 rounded-2xl space-y-6">
        {!activeDevice.isBlocked ? (
          <>
            <TransactionsList walletId={wallets[activeWalletIndex].id} />

            <div className="flex items-center justify-center">
              {statementLoading ? (
                <Loader responsive="true" size={0.05} />
              ) : (
                <button
                  className="text-sm text-app-blue leading-5 font-medium underline"
                  onClick={handleDownload}
                >
                  {t("wallet.download.label")}
                </button>
              )}
            </div>
          </>
        ) : (
          <div className="flex items-center gap-x-1.5">
            <HiOutlineLockClosed size={14} />
            <p className="leading-6 font-semibold">
              {activeDevice.type === "CARD"
                ? t("wallet.blockedCard")
                : t("wallet.blockedWearable")}
            </p>
          </div>
        )}
      </div>

      <BlockModal
        email={user?.email}
        deviceType={activeDevice.type}
        isShowingBlock={isShowingBlock}
        setIsShowingBlock={setIsShowingBlock}
        reloadWallets={() => {
          setActiveDevice({ ...activeDevice, isBlocked: true });
          setDevices(
            devices.map((d) =>
              d.id === activeDevice.id ? { ...d, isBlocked: true } : d
            )
          );
        }}
        deviceNumber={activeDevice.deviceNumber}
      />
      {isShowingUnblock && user.status !== "BLOCKED" && (
        <UnblockModal
          isShowingUnblock={isShowingUnblock}
          setIsShowingUnblock={setIsShowingUnblock}
          email={user?.email}
          unblock={unblock}
        />
      )}
      {isShowingUnblock && user.status === "BLOCKED" && (
        <UnblockModalBlockedUser
          isShowingUnblock={isShowingUnblock}
          setIsShowingUnblock={setIsShowingUnblock}
          email={user?.email}
          unblock={unblock}
        />
      )}
    </section>
  );
};

export default Wallet;
